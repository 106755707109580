import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import BankMasterTable from "../tables/BankMasterTable";
import { Storage } from "../../login/Storagesetting";
import DashboardLayout from "../../components/DashboardLayout";

const BankMaster = () => {
  const roleId = Storage.getItem("roleIDs");
  const Layout = roleId === "1" ? AdminDashboardLayout : DashboardLayout;

  return (
    <>
      <Helmet>
        <title>Bank</title>
      </Helmet>
      <Layout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Bank</li>
          </ol>
        </nav>

        <section className="section dashboard adminDashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">Bank </h4>
                <BankMasterTable />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default BankMaster;
