import axios from "axios";
import { APIURL } from "../constant";

export default function ErrorsLogApi() {
  const ErrorlogData = async (JSONSent, ExceptionInfo, APIName) => {
    try {
      const response = await axios.post(APIURL + "Admin/InsertResponse", {
        JSONSent: JSONSent,
        ExceptionInfo: ExceptionInfo,
        APIName: APIName,
      });
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  return {
    ErrorlogData,
  };
}
