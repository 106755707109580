import React from "react";
import { Helmet } from "react-helmet";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import DirectivesTables from "../tables/DirectivesTables";
import DashboardLayout from "../../components/DashboardLayout";
import { Storage } from "../../login/Storagesetting";

const Directives = () => {
  const roleId = Storage.getItem("roleIDs");

  const Layout = roleId === "1" ? AdminDashboardLayout : DashboardLayout;
  return (
    <>
      <Helmet>
        <title>Directives</title>
      </Helmet>
      <Layout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Directives</li>
          </ol>
        </nav>

        <section className="section dashboard adminDashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">Directives</h4>
                {/* DirectivesTables */}
                <DirectivesTables />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Directives;
