import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { APIURL } from "../../constant";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import moment from "moment";
const HoliDayTable = () => {
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [formerr, setformerr] = useState();
  const [updateerr, setupdateerr] = useState();
  const [holidayDate, setHolidayDate] = useState(new Date());
  const [yearValue, setYearValue] = useState(new Date());
  const [maxyearValue, setmaxYearValue] = useState(new Date());

  const [updateHolidayDate, setUpdateHolidayDate] = useState(new Date());
  const csvLinkRef = useRef();
  const [errors, setErrors] = useState(false);
  const handleClick = (title) => {
    alert(`Title: ${title}`);
  };

  // country update start

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const UpdateModalClose = () => {
    setShowUpdateModal(false);
    setUpdateData({
      holidayDetails: "",
    });
    setHolidayDate(new Date());
    setupdateerr("");
  };
  const [updateData, setUpdateData] = useState({
    holidayDetails: "",
  });

  const changeUpdateForm = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [updateID, setUpdateID] = useState("");
  const handleUpdate = async (id) => {
    setShowUpdateModal(true);
    setUpdateID(id);
    const TableId = {
      id: id,
    };

    try {
      const response = await fetch(APIURL + "Admin/GetHolidayByID", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(TableId),
      });

      const data = await response.json();
      if (data.responseCode === "200") {
        setUpdateData(data.responseData);
        setUpdateHolidayDate(data.responseData.date);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleHolidayDelete = async (id) => {
    const TableId = {
      id: id,
    };
    try {
      const response = await fetch(APIURL + "Admin/DeleteHoliday", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(TableId),
      });

      const data = await response.json();
      toast.success(data.responseMessage, { autoClose: 1000 });
      setTimeout(() => {
        table_Data();
      }, 2000);
      if (data.responseCode === "200") {
        table_Data();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUpdateData = async () => {
    const updateValue = {
      ID: updateID,
      Date: moment(updateHolidayDate).format("YYYY-MM-DD"),
      Day: moment(updateHolidayDate).format("dddd"),
      HolidayDetails: updateData?.holidayDetails,
    };

    try {
      if (isUpdateValid()) {
        const response = await fetch(APIURL + "Admin/UpdateHoliday", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateValue),
        });

        const data = await response.json();
        setToastDisplayed(true);
        if (data.responseCode === "200") {
          toast.success(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            UpdateModalClose();
            table_Data();
            setSearchText("");
            setUpdateData({
              holidayDetails: "",
            });
            setToastDisplayed(false);
          }, 2500);
        } else {
          toast.warning(data.responseMessage);
          setTimeout(() => {
            table_Data();
            setToastDisplayed(false);
          }, 2500);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // country update end
  //countetr list api start
  const table_Data = async () => {
    const year = {
      Year: moment(yearValue).format("yyyy"),
    };
    const year_json = JSON.stringify(year);
    try {
      const response = await fetch(APIURL + "Admin/HolidayListByYear", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: year_json,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setTableData(data.responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //countetr list api end
  useEffect(() => {
    table_Data();
  }, [yearValue]);
  const columns = [
    {
      name: "Sr.",
      selector: (row) => row.id,
      sortable: true,
      searchable: true,
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      searchable: true,
      width: "25%",
      cell: (row) => <span>{moment(row.date).format("DD MMM YYYY")}</span>,
    },
    {
      name: "Day",
      selector: (row) => row.day,
      sortable: true,
      searchable: true,
      width: "25%",
    },
    {
      name: "Details",
      selector: (row) => row.holidayDetails,
      sortable: true,
      searchable: true,
      width: "25%",
      // cell: row => <span>{row.status === 1 ? <span className="badge rounded-pill bg-success">Active</span> : <span className="badge rounded-pill bg-warning text-dark">Inactive</span>}</span>,
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          {" "}
          <Link to="" className="me-2" onClick={() => handleUpdate(row.id)}>
            <i className="bi bi-pencil-square"></i>
          </Link>
          <Link
            to=""
            className="me-2"
            onClick={() => handleHolidayDelete(row.id)}
          >
            <i className="bi bi-trash"></i>
          </Link>
        </>
      ),
    },
  ];

  // add country form start
  const [showForm, setShowForm] = useState(false);
  const handleFormClose = () => {
    setShowForm(false);
    setHolidayForm({
      holidayDetails: "",
    });
    setHolidayDate(new Date());
    setformerr("");
  };

  const handleFormShow = () => setShowForm(true);
  const [holidayForm, setHolidayForm] = useState({
    holidayDetails: "",
  });

  const changeHandelForm = (e) => {
    const { name, value } = e.target;
    setHolidayForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const application_data = {
      Date: moment(holidayDate).format("YYYY-MM-DD"),
      Day: moment(holidayDate).format("dddd"),
      HolidayDetails: holidayForm.holidayDetails,
    };
    const application_data_json = JSON.stringify(application_data);
    try {
      if (isValid()) {
        const application_responce = await fetch(APIURL + "Admin/AddHoliday", {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          },
          body: application_data_json,
        });
        const data = await application_responce.json();
        setToastDisplayed(true);
        if (data.responseCode === "200") {
          setHolidayForm({
            holidayDetails: "",
          });
          toast.success(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            handleFormClose();
            setSearchText("");
            table_Data();
            setSearchText("");
            setToastDisplayed(false);
          }, 2500);
        } else {
          toast.warning(data.responseMessage, { autoClose: 2000 });
          setTimeout(() => {
            table_Data();
            setToastDisplayed(false);
          }, 2500);
        }
      }
    } catch (error) {
      console.log("Fetching Error", error);
    }
  };

  // validation start
  const isValid = () => {
    const newErrors = {};
    let valid = true;
    if (!holidayForm.holidayDetails) {
      newErrors.holidayDetails = "Name is required.";
      valid = false;
    }
    if (holidayDate == null) {
      newErrors.holidayDate = "Date is required";
      valid = false;
    }
    setformerr(newErrors);
    return valid;
  };
  // validation end
  // validation update start
  const isUpdateValid = () => {
    const newErrors = {};
    let valid = true;
    if (!updateData.holidayDetails) {
      newErrors.holidayDetails = "Name is required.";
      valid = false;
    }
    if (updateHolidayDate == null) {
      newErrors.updateHolidayDate = "Date is required";
      valid = false;
    }
    setupdateerr(newErrors);
    return valid;
  };

  const filteredData = tableData?.filter(
    (item) =>
      item.countryName?.toLowerCase().includes(searchText?.toLowerCase()) ||
      (item.id && item.id.toString().includes(searchText)) ||
      item.holidaY_DETAILS?.toLowerCase().includes(searchText?.toLowerCase())
  );

  return (
    <>
      <>
        <DataTable
          columns={columns}
          data={filteredData}
          defaultSortFieldId={1}
          persistTableHead={true}
          defaultSortAsc={true}
          pagination
          paginationRowsPerPageOptions={[10, 50, 100]}
          highlightOnHover
          dense
          striped
          fixedHeader
          subHeader
          subHeaderComponent={
            <div className="admintablesearch">
              <div className="tablesearch_bx">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="position-relative holidayFilterBox">
                <label>Filter by holiday year</label>
                <DatePicker
                  placeholderText="Year"
                  selected={yearValue}
                  onChange={(date) => setYearValue(date)}
                  minDate="01/01/2017"
                  maxDate={
                    // new Date(yearValue.getTime() + 365 * 24 * 60 * 60 * 1000)
                    new Date(maxyearValue.getTime() + 365 * 24 * 60 * 60 * 1000)
                  }
                  dropdownMode="select"
                  showYearPicker
                  dateFormat="yyyy"
                  // yearItemNumber={9}
                />
              </div>
              <div className="table-btn-bx">
                <Button onClick={handleFormShow}>Add Holiday Calendar</Button>
              </div>
            </div>
          }
        />
      </>

      {/* CountryMaster Form modal */}
      <Modal show={showForm} onHide={handleFormClose} backdrop="static">
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Add Holiday Calendar</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0 holidayModalBody">
                <div className="form-bx">
                  <p className="datepickerbox">
                    <DatePicker
                      placeholderText="Date"
                      closeOnScroll={(e) => e.target === document}
                      selected={holidayDate}
                      onChange={(date) => setHolidayDate(date)}
                      peekNextMonth
                      minDate={new Date()}
                      // minDate="01/01/2017"
                      // showMonthDropdown
                      // showYearDropdown
                      // maxDate={
                      //     new Date(holidayDate.getTime() + 365 * 24 * 60 * 60 * 1000)
                      //   }
                      dropdownMode="select"
                      dateFormat="dd/MMMM/yyyy"
                      useWeekdaysShort={true}
                    />
                  </p>
                  <span className="sspan"></span>

                  {holidayDate == null ? (
                    formerr?.holidayDate ? (
                      <span className="errormsg">{formerr?.holidayDate}</span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="form-bx">
                  <label>
                    <input
                      type="text"
                      name="holidayDetails"
                      className="fomcontrol"
                      value={holidayForm.holidayDetails}
                      placeholder="Name"
                      onChange={(e) => {
                        changeHandelForm(e);
                      }}
                      required
                    />
                    <span className="sspan"></span>
                  </label>
                  {holidayForm.holidayDetails?.length == 0 ? (
                    formerr?.holidayDetails ? (
                      <span className="errormsg">
                        {formerr?.holidayDetails}
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="p-0">
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={toastDisplayed ? true : false}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>

      {/* CountryMaster Form update modal */}
      <Modal show={showUpdateModal} onHide={UpdateModalClose} backdrop="static">
        <div className="application-box editmodal-change">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>Update Holiday Calendar</Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0 updateModalBody">
                <div className="form-bx mb-4">
                  <p className="form-label">Date</p>
                  <p className="mb-0 datepickerbox">
                    <DatePicker
                      placeholderText="Date"
                      closeOnScroll={(e) => e.target === document}
                      selected={updateHolidayDate}
                      onChange={(date) => setUpdateHolidayDate(date)}
                      peekNextMonth
                      // showMonthDropdown
                      // showYearDropdown
                      minDate={new Date()}
                      dropdownMode="select"
                      dateFormat="dd/MMMM/yyyy"
                      useWeekdaysShort={true}
                    />
                  </p>
                  <span className="sspan"></span>
                  {updateHolidayDate == null ? (
                    updateerr?.updateHolidayDate ? (
                      <span className="errormsg">
                        {updateerr?.updateHolidayDate}
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-bx mb-4">
                  <p className="form-label">Name</p>
                  <label>
                    <input
                      type="text"
                      name="holidayDetails"
                      className="fomcontrol"
                      value={updateData.holidayDetails}
                      placeholder="Name"
                      onChange={(e) => {
                        changeUpdateForm(e);
                      }}
                      required
                    />
                    <span className="sspan"></span>
                  </label>
                  {updateData.holidayDetails?.length == 0 ? (
                    updateerr?.holidayDetails ? (
                      <span className="errormsg">
                        {updateerr?.holidayDetails}
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="p-0">
                <Button
                  variant="primary"
                  onClick={handleUpdateData}
                  disabled={toastDisplayed ? true : false}
                >
                  Update
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HoliDayTable;
