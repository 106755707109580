import React, { useEffect, useRef, useState } from "react";
import ExportformDynamicField from "./ExportformDynamicField";
import { Storage } from "../login/Storagesetting";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { APIURL, ImageAPI } from "../constant";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Modal from "react-bootstrap/Modal";
import { TailSpin } from "react-loader-spinner";
import UpdatePopupMessage from "./UpdatePopupMessage";
import ImportDashboardViewDetails from "./ImportDashboardViewDetails";

/* Tiptp Editor Starts */
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

const ImportNewRequestForm = () => {
  const navigate = useNavigate();

  const {
    currency,
    companies,
    GovernmentAgencies,
    applicantTypes,
    sectorData,
    masterBank,
    ImpSupervisors,
    countries,
  } = ExportformDynamicField();

  const supervisorDesc = useRef(null);
  const CustomTableCell = TableCell.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        backgroundColor: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-background-color"),
          renderHTML: (attributes) => {
            return {
              "data-background-color": attributes.backgroundColor,
              style: `background-color: ${attributes.backgroundColor}`,
            };
          },
        },
      };
    },
  });

  const BPNCodeRef = useRef(null);
  const TINRef = useRef(null);
  const amountRef = useRef(null);
  const applicantRef = useRef(null);
  const BeneficiaryNameRef = useRef(null);
  const applicantCommentsRef = useRef(null);
  const applicantReferenceNumberRef = useRef(null);
  const applicationTypeRef = useRef(null);
  const bankSupervisorRef = useRef(null);
  const companyNameRef = useRef(null);
  const currencyRef = useRef(null);
  const govtAgencieRef = useRef(null);
  const purposeApplicationRef = useRef(null);

  const relatedexchangeControlNumberRef = useRef(null);
  const sectorRef = useRef(null);
  const subsectorRef = useRef(null);
  const typeExporterRef = useRef(null);
  const rateRef = useRef(null);
  const usdEquivalentRef = useRef(null);
  const PECANNumberRef = useRef(null);

  const UserID = Storage.getItem("userID");
  const bankID = Storage.getItem("bankID");
  const userName = Storage.getItem("userName");
  const bankName = Storage.getItem("bankName");
  const roleID = Storage.getItem("roleIDs");
  const bankidcheck =
    roleID == 2 ? "1" : roleID == 10 ? "2" : roleID == 11 ? "3" : "1";
  const applicantTypeLocal = Storage.getItem("applicantType");

  const [startDate, setStartDate] = useState(new Date());
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [getCompanyName, setgetCompanyName] = useState(null);
  const [submitbuttonhide, setsubmitbuttonhide] = useState(false);
  const [updatepopup, setupdatepopup] = useState(false);

  const [registerusertype, setregisterusertype] = useState(bankidcheck);
  const [ImportForm, setImporttForm] = useState({
    user: "",
    bankName: bankName,
    purposeApplication: "",
    PECANNumber: "",
    typeExporter: "",
    BeneficiaryName: "",
    baneficiaryCountry: "",
    govtAgencie: "",
    BPNCode: "",
    TINNumber: "",
    applicant: "",
    applicantReferenceNumber: "",
    applicationType: "",
    exporterType: registerusertype,
    currency: "",
    amount: "",
    rate: "",
    usdEquivalent: "",
    relatedexchangeControlNumber: "",
    sector: "",
    subsector: "",
    applicantComments: "",
    bankSupervisor: "",
  });
  const [ValidateChange, setValidateChange] = useState({
    PECANNumber: "",
  });
  const [ValidateShow, setValidateShow] = useState(false);
  const [ValidateRBZ, setValidateRBZ] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [allcommentRenew, setallcomment] = useState([]);
  const [tatHistoryRenew, setTatHistory] = useState([]);
  const [noDataCommentRenew, setNoDataComment] = useState([]);
  const [applicationDetail, setApplicationDetail] = useState({});
  const [applicationmessage, setApplicationmessage] = useState("");
  const [responceCountRenew, setresponceCount] = useState([]);
  const [Actiondata, setActiondata] = useState([]);
  const handleViewData = (id) => {
    setShowUpdateModal(true);
  };
  const [files, setFiles] = useState([]);
  const [otherfiles, setOtherfiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [applicationType, setapplicationType] = useState([]);
  const [subsectorData, setsubsectorData] = useState([]);
  const [curRate, setCurrate] = useState();
  const [selectuserRole, setselectuserRole] = useState("");
  const [checkSupervisor, setcheckSupervisor] = useState(
    roleID == 4 ? true : false
  );
  const [attachmentData, setAttachmentData] = useState([]);
  const [otherfilesupload, setOtherfilesupload] = useState([]);
  const [value, setValue] = useState("Company Name");
  const [userRole, setUserrole] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [getBankID, setGetBankID] = useState("");
  const [getalluser, setGetalluser] = useState([]);
  const [Description, setDescription] = useState("");
  const [content, setEditorContent] = useState("");

  const fileInputRefsother = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleFormClose = () => setShowUpdateModal(false);
  const heading = "Application Submitted Successfully!";
  const para = "Import application request submitted successfully!";

  const fileInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // for input data start
  const changeHandelForm = (e) => {
    let newErrors = {};
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    const name = e.target.name;
    const value = e.target.value;
    if (name === "PECANNumber" && value.charAt(0) === " ") {
      newErrors.PECANNumber = "First character cannot be a blank space";
    } else if (
      name === "PECANNumber" &&
      (specialChars.test(value) ||
        value?.includes("_") ||
        value?.includes("+") ||
        value?.includes("=") ||
        value?.includes("'") ||
        value?.includes(";") ||
        value?.includes("[") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("]"))
    ) {
      newErrors.PECANNumber = "special characters not allowed";
    } else if (name === "TINNumber" && value.charAt(0) === " ") {
      newErrors.TINNumber = "First character cannot be a blank space";
    } else if (
      name === "TINNumber" &&
      (specialChars.test(value) ||
        value?.includes("_") ||
        value?.includes("+") ||
        value?.includes("=") ||
        value?.includes("'") ||
        value?.includes(";") ||
        value?.includes("[") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("]"))
    ) {
      newErrors.TINNumber = "special characters not allowed";
    } else if (name === "applicant" && value.charAt(0) === " ") {
      newErrors.applicant = "First character cannot be a blank space";
    } else if (
      name === "applicant" &&
      (specialChars.test(value) ||
        value?.includes("_") ||
        value?.includes("+") ||
        value?.includes("=") ||
        value?.includes("'") ||
        value?.includes(";") ||
        value?.includes("[") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("]"))
    ) {
      newErrors.applicant = "special characters not allowed";
    } else if (name === "BeneficiaryName" && value.charAt(0) === " ") {
      newErrors.BeneficiaryName = "First character cannot be a blank space";
    } else if (
      name === "BeneficiaryName" &&
      (specialChars.test(value) ||
        value?.includes("_") ||
        value?.includes("+") ||
        value?.includes("=") ||
        value?.includes("'") ||
        value?.includes(";") ||
        value?.includes("[") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("]"))
    ) {
      newErrors.BeneficiaryName = "special characters not allowed";
    } else if (name === "BPNCode" && value.charAt(0) === " ") {
      newErrors.BPNCode = "First character cannot be a blank space";
    } else if (
      name === "BPNCode" &&
      (specialChars.test(value) ||
        value?.includes("_") ||
        value?.includes("+") ||
        value?.includes("=") ||
        value?.includes("'") ||
        value?.includes(";") ||
        value?.includes("[") ||
        value?.includes("`") ||
        value?.includes("~") ||
        value?.includes("]"))
    ) {
      newErrors.BPNCode = "special characters not allowed";
    } else if (name === "applicantComments" && value.charAt(0) === " ") {
      newErrors.applicantComments = "First character cannot be a blank space";
    } else if (name === "amount" && value.length > 10) {
      newErrors.amount = "Max 10 digit allow";
    } else {
      setImporttForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrors(newErrors);

    if (name === "sector" && value != "") {
      axios
        .post(APIURL + "Master/GetSubSectorBySectorID", {
          SectorID: value,
        })
        .then((res) => {
          if (res.data.responseCode == "200") {
            setsubsectorData(res.data.responseData);
          } else {
            setsubsectorData([]);
            console.log(res.data.responseMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (name === "currency" && value != "") {
      axios
        .post(APIURL + "Master/GetRateByCurrencyID", {
          Id: value,
        })
        .then((res) => {
          if (res.data.responseCode == "200") {
            setCurrate(res.data.responseData.currencyRate);
          } else {
            setCurrate([]);
            console.log(res.data.responseMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (name === "applicationType") {
      axios
        .post(APIURL + "Master/GetAttachmentData", {
          ApplicationTypeID: value != "" ? value : -1,
          ApplicationSubTypeID: "0",
        })
        .then((res) => {
          if (res.data.responseCode == "200") {
            setAttachmentData(res.data.responseData);
          } else {
            setAttachmentData([]);
            setFiles([]);
            setOtherfiles([]);
            setOtherfilesupload([]);
          }
        });
    }
  };
  // for input data end

  // convert amount for USD Equivalent start
  const w = curRate * parseFloat(ImportForm.amount);
  const x = w?.toFixed(2);
  const y = parseFloat(x);
  const convertedRate = y.toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
  // convert amount for USD Equivalent end

  // Get Application Types By DepartmentID start
  const GetApplicationTypes = async () => {
    await axios
      .post(APIURL + "Master/GetApplicationTypesByDepartmentID", {
        DepartmentID: "3",
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setapplicationType(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const supervisorHangechangeRole = (e) => {
    const value = e.target.value;
    setErrors({});
    setselectuserRole(value);
    if (value == "") {
      setGetalluser([]);
    } else {
      axios
        .post(APIURL + "User/GetUsersByRoleID", {
          RoleID: value,
          DepartmentID: "3",
          UserID: UserID.replace(/"/g, ""),
        })
        .then((res) => {
          if (res.data.responseCode == "200") {
            setGetalluser(res.data.responseData);
          } else {
            setGetalluser([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getRoleHandle = async () => {
    await axios
      .post(APIURL + "Master/GetRoles", {
        RoleID: "4",
        Status: "35",
        DepartmentID: "3",
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setUserrole(res.data.responseData);
        } else {
          setUserrole([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetApplicationTypes();
    getRoleHandle();
  }, []);
  // End Get Application Types By DepartmentID start

  const handleUsertype = (e) => {
    setregisterusertype(e.target.value);
  };

  const handleAddMore = (e) => {
    setOtherfiles([...otherfiles, null]);
  };

  const MenuBar = ({ editor }) => {
    if (!editor) {
      return null;
    }

    return (
      <>
        <button
          type="button"
          title="Insert Table"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
        >
          <i className="bi bi-table"></i>
        </button>
        <button
          type="button"
          title="Add Column Before"
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
        >
          <i className="bi bi-list-columns-reverse"></i>
        </button>
        <button
          type="button"
          title="Add Column After"
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
        >
          <i className="bi bi-list-columns"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
        >
          <i className="bi bi-archive"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
        >
          <i className="bi bi-arrow-bar-up"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
        >
          <i className="bi bi-arrow-bar-down"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
        >
          <i className="bi bi-archive"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().deleteTable().run()}
          disabled={!editor.can().deleteTable()}
        >
          <i className="bi bi-archive"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().mergeCells().run()}
          disabled={!editor.can().mergeCells()}
        >
          <i className="bi bi-union"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().splitCell().run()}
          disabled={!editor.can().splitCell()}
        >
          splitCell
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          disabled={!editor.can().toggleHeaderColumn()}
        >
          <i className="bi bi-layout-split"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          disabled={!editor.can().toggleHeaderRow()}
        >
          <i className="bi bi-toggle-off"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeaderCell().run()}
          disabled={!editor.can().toggleHeaderCell()}
        >
          <i className="bi bi-toggle-off"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().mergeOrSplit().run()}
          disabled={!editor.can().mergeOrSplit()}
        >
          <i className="bi bi-subtract"></i>
        </button>
        <button
          type="button"
          onClick={() =>
            editor
              .chain()
              .focus()
              .setCellAttribute("backgroundColor", "#FAF594")
              .run()
          }
          disabled={
            !editor.can().setCellAttribute("backgroundColor", "#FAF594")
          }
        >
          <i className="bi bi-kanban"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().fixTables().run()}
          disabled={!editor.can().fixTables()}
        >
          <i className="bi bi-file-spreadsheet"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().goToNextCell().run()}
          disabled={!editor.can().goToNextCell()}
        >
          <i className="bi bi-arrow-right-square"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().goToPreviousCell().run()}
          disabled={!editor.can().goToPreviousCell()}
        >
          <i className="bi bi-arrow-left-square"></i>
        </button>
        <button
          type="button"
          title="Bold"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <i className="bi bi-type-bold"></i>
        </button>
        <button
          type="button"
          title="Italic"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <i className="bi bi-type-italic"></i>
        </button>
        <button
          type="button"
          title="Strike"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <i className="bi bi-type-strikethrough"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive("code") ? "is-active" : ""}
        >
          <i className="bi bi-code-slash"></i>
        </button>
        <button
          type="button"
          title="Paragraph"
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <i className="bi bi-paragraph"></i>
        </button>
        <button
          type="button"
          title="H1"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h1"></i>
        </button>
        <button
          type="button"
          title="H2"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h2"></i>
        </button>
        <button
          type="button"
          title="H3"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h3"></i>
        </button>
        <button
          type="button"
          title="H4"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h4"></i>
        </button>
        <button
          type="button"
          title="H5"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive("heading", { level: 5 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h5"></i>
        </button>
        <button
          type="button"
          title="H6"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive("heading", { level: 6 }) ? "is-active" : ""
          }
        >
          <i className="bi bi-type-h6"></i>
        </button>
        <button
          type="button"
          title="Bullet List"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <i className="bi bi-list-ul"></i>
        </button>
        <button
          type="button"
          title="Ordered List"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <i className="bi bi-list-ol"></i>
        </button>
        <button
          type="button"
          title="Blockquote"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          <i className="bi bi-quote"></i>
        </button>
        <button
          type="button"
          title="Horizontal Rule"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <i className="bi bi-hr"></i>
        </button>
        <button
          type="button"
          title="Hard Break"
          onClick={() => editor.chain().focus().setHardBreak().run()}
        >
          <i className="bi bi-file-break"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
        >
          <i className="bi bi-text-left"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
        >
          <i className="bi bi-text-center"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        >
          <i className="bi bi-text-right"></i>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          className={
            editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
          }
        >
          <i className="bi bi-justify"></i>
        </button>
        <span className="setcolorcss">
          <input
            type="color"
            className="colorswatch"
            onInput={(event) =>
              editor.chain().focus().setColor(event.target.value).run()
            }
            value={editor.getAttributes("textStyle").color}
            data-testid="setColor"
          />
          <button
            type="button"
            onClick={() => editor.chain().focus().unsetColor().run()}
            data-testid="unsetColor"
          >
            <i className="bi bi-palette-fill"></i>
          </button>
        </span>
      </>
    );
  };

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      Table.configure({ resizable: true }),
      Text,
      Color,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      TableRow,
      TableHeader,
      CustomTableCell,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: content,
    onUpdate({ editor }) {
      setDescription(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      setDescription(editor.getHTML());
    }
  }, []);

  const handleFileChange = (e, label) => {
    const file = e.target.files[0];
    const index = files.findIndex((item) => item.label === label);
    if (index !== -1) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles];
        newFiles[index] = { file, label };
        return newFiles;
      });
    } else {
      setFiles((prevFiles) => [...prevFiles, { file, label }]);
    }
  };

  const handleOthrefile = (e, id) => {
    const otherfile = e.target.files[0];
    setOtherfilesupload([...otherfilesupload, { otherfile, id }]);
  };

  const HandelSupervisorcheck = (e) => {
    setcheckSupervisor(!checkSupervisor);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    const numericRegex = /\d/;

    if (
      registerusertype === "1" &&
      (getCompanyName == null ||
        value === "Company Name" ||
        getCompanyName.label == "")
    ) {
      newErrors.companyName = "Company Name is required";
      valid = false;
    }
    if (startDate == null) {
      newErrors.date = "Application Date is required";
      valid = false;
    }
    if ((Description == "" || Description == "<p></p>") && roleID == 2) {
      newErrors.Description = "Recommendation is required";
      valid = false;
    }
    if (ImportForm.applicationType === "") {
      newErrors.applicationType = "Nature of Application is required";
      valid = false;
    }
    if (checkSupervisor === true && selectuserRole === "" && roleID == 4) {
      newErrors.selectuserRole = "Role is required";
      valid = false;
    }
    if (
      bankID == "" &&
      registerusertype === "3" &&
      ImportForm.govtAgencie === ""
    ) {
      newErrors.govtAgencie = "Government agencies name is required";
      valid = false;
    }
    if (registerusertype === "2" && ImportForm.applicant === "") {
      newErrors.applicant = "Applicant name is required";
      valid = false;
    }
    if (ImportForm.currency === "") {
      newErrors.currency = "Currency is required";
      valid = false;
    }
    if (ImportForm.amount === "") {
      newErrors.amount = "Amount is required";
      valid = false;
    }
    if (ImportForm.sector === "") {
      newErrors.sector = "Sector is required";
      valid = false;
    }
    if (ImportForm.subsector === "" && ImportForm.sector != "2") {
      newErrors.subsector = "Subsector is required";
      valid = false;
    }
    if (ImportForm.applicantComments === "") {
      newErrors.applicantComments = "Applicant comments is required";
      valid = false;
    }
    if (checkSupervisor == true && ImportForm.bankSupervisor === "") {
      newErrors.bankSupervisor = "Bank supervisor is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const generateRandomNumber = () => {
    return Math.floor(10000 + Math.random() * 90000);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    if (validateForm()) {
      setsubmitbuttonhide(true);
      await axios
        .post(APIURL + "ImportApplication/CreateImportApplication", {
          UserID: UserID.replace(/"/g, ""),
          BankID: roleID == 4 ? getBankID : bankID,
          RoleID: roleID,
          DepartmentID: "3",
          ApplicationDate: moment(startDate).format("YYYY-MM-DD"),
          PECANumber: ValidateChange.PECANNumber.toUpperCase(),
          ApplicantType: registerusertype,
          Name:
            registerusertype === "2" && bankID !== ""
              ? ImportForm.applicant
              : "",
          CompanyID:
            registerusertype === "1" && bankID !== ""
              ? getCompanyName?.value
              : "",
          BPNCode:
            registerusertype === "1" && bankID !== ""
              ? filtertin_bpn?.bpnNumber?.toUpperCase()
              : "",
          TINNumber:
            registerusertype === "1" && bankID !== ""
              ? filtertin_bpn?.tinNumber?.toUpperCase()
              : "",
          ApplicationTypeID: ImportForm.applicationType,
          BeneficiaryName: ImportForm.BeneficiaryName,
          BeneficiaryCountry: ImportForm.baneficiaryCountry,
          Currency: ImportForm.currency,
          Amount: ImportForm.amount,
          Description: Description,
          Rate: curRate,
          USDEquivalent: convertedRate,
          Sector: ImportForm.sector,
          SubSector: ImportForm.subsector,
          ApplicantComment: ImportForm.applicantComments,
          AssignedTo: checkSupervisor == true ? ImportForm.bankSupervisor : "",
          AssignedToRoleID:
            roleID == 2 && checkSupervisor == true ? 3 : selectuserRole,
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            for (let i = 0; i < files?.length; i++) {
              formData.append("files", files[i].file);
              formData.append("Label", files[i].label);
            }
            formData.append(
              "RBZReferenceNumber",
              res.data.responseData.rbzReferenceNumber
            );
            formData.append("ApplicationID", res.data.responseData.id);
            formData.append("DepartmentID", "3");
            formData.append("UserID", UserID.replace(/"/g, ""));
            axios
              .post(ImageAPI + "File/UploadFile", formData)
              .then((res) => {
                setsubmitbuttonhide(false);
                setupdatepopup(true);
                console.log("Uploaded");
              })
              .catch((err) => {
                console.log("file Upload ", err);
              });
            Storage.setItem(
              "generatedNumber",
              res.data.responseData.rbzReferenceNumber
            );
          } else {
            toast.error(res.data.responseMessage);
            setsubmitbuttonhide(false);
          }
        })
        .catch((err) => {
          setsubmitbuttonhide(false);
          console.log(err);
        });
      setImporttForm({
        user: "",
        bankName: bankName,
        purposeApplication: "",
        typeExporter: "",
        BeneficiaryName: "",
        govtAgencie: "",
        BPNCode: "",
        TINNumber: "",
        applicant: "",
        applicantReferenceNumber: "",
        applicantYear: "2024",
        exporterType: "",
        currency: "",
        amount: "",
        rate: "",
        usdEquivalent: "",
        relatedexchangeControlNumber: "",
        sector: "",
        subsector: "",
        applicantComments: "",
        bankSupervisor: "",
      });
      if (BPNCodeRef.current) BPNCodeRef.current.value = "";
      if (TINRef.current) TINRef.current.value = "";
      if (amountRef.current) amountRef.current.value = "";
      if (applicantRef.current) applicantRef.current.value = "";
      if (applicantCommentsRef.current) applicantCommentsRef.current.value = "";
      if (BeneficiaryNameRef.current) BeneficiaryNameRef.current.value = "";
      if (applicantReferenceNumberRef.current)
        applicantReferenceNumberRef.current.value = "";
      if (applicationTypeRef.current) applicationTypeRef.current.value = "";
      if (bankSupervisorRef.current) bankSupervisorRef.current.value = "";
      if (companyNameRef.current) companyNameRef.current.value = "";
      if (currencyRef.current) currencyRef.current.value = "";
      if (govtAgencieRef.current) govtAgencieRef.current.value = "";
      if (purposeApplicationRef.current)
        purposeApplicationRef.current.value = "";
      if (relatedexchangeControlNumberRef.current)
        relatedexchangeControlNumberRef.current.value = "";
      if (sectorRef.current) sectorRef.current.value = "";
      if (subsectorRef.current) subsectorRef.current.value = "";
      if (typeExporterRef.current) typeExporterRef.current.value = "";
      if (usdEquivalentRef.current) usdEquivalentRef.current.value = "";
      if (rateRef.current) rateRef.current.value = "";
      if (PECANNumberRef.current) PECANNumberRef.current.value = "";
    } else {
      if (!toastDisplayed) {
        toast.warning("Please fill all mandatory fields");
      }
      setToastDisplayed(true);
    }
  };

  const clearInputFile = (index) => {
    if (fileInputRefs[index]?.current) fileInputRefs[index].current.value = "";
  };

  const clearInputFileother = (index) => {
    if (fileInputRefsother[index]?.current)
      fileInputRefsother[index].current.value = "";
  };

  const closePopupHandle = () => {
    navigate("/ImportDashboard");
    setgetCompanyName("");
    setupdatepopup(false);
    setImporttForm({
      user: "",
      bankName: bankName,
      purposeApplication: "",
      typeExporter: "",
      govtAgencie: "",
      BPNCode: "",
      TIN: "",
      applicant: "",
      applicantReferenceNumber: "",
      exporterType: "",
      currency: "",
      amount: "",
      rate: "",
      usdEquivalent: "",
      relatedexchangeControlNumber: "",
      sector: "",
      subsector: "",
      applicantComments: "",
      bankSupervisor: "",
    });
    if (BPNCodeRef.current) BPNCodeRef.current.value = "";
    if (TINRef.current) TINRef.current.value = "";
    if (amountRef.current) amountRef.current.value = "";
    if (applicantRef.current) applicantRef.current.value = "";
    if (applicantCommentsRef.current) applicantCommentsRef.current.value = "";
    if (applicantReferenceNumberRef.current)
      applicantReferenceNumberRef.current.value = "";
    if (applicationTypeRef.current) applicationTypeRef.current.value = "";
    if (bankSupervisorRef.current) bankSupervisorRef.current.value = "";
    if (companyNameRef.current) companyNameRef.current.value = "";
    if (currencyRef.current) currencyRef.current.value = "";
    if (govtAgencieRef.current) govtAgencieRef.current.value = "";
    if (purposeApplicationRef.current) purposeApplicationRef.current.value = "";
    if (relatedexchangeControlNumberRef.current)
      relatedexchangeControlNumberRef.current.value = "";
    if (sectorRef.current) sectorRef.current.value = "";
    if (subsectorRef.current) subsectorRef.current.value = "";
    if (typeExporterRef.current) typeExporterRef.current.value = "";
    if (usdEquivalentRef.current) usdEquivalentRef.current.value = "";
    if (rateRef.current) rateRef.current.value = "";
    setOtherfilesupload([]);
    setFiles([]);
    setErrors({});
    setregisterusertype(applicantTypeLocal);
  };

  const removeUserImage = (label) => {
    const updatedUserFile = files?.filter((item) => item.label !== label);
    setFiles(updatedUserFile);
  };

  const ResetHandleData = () => {
    setgetCompanyName(null);
    setselectuserRole("");
    setGetBankID("");
    setGetalluser([]);
    setupdatepopup(false);
    setOtherfilesupload([]);
    setFiles([]);
    setErrors({});
    setregisterusertype(applicantTypeLocal);
    setImporttForm({
      user: "",
      bankName: bankName,
      purposeApplication: "",
      typeExporter: "",
      govtAgencie: "",
      BPNCode: "",
      TIN: "",
      applicant: "",
      applicantReferenceNumber: "",
      applicationType: "",
      exporterType: "",
      currency: "",
      amount: "",
      rate: "",
      usdEquivalent: "",
      relatedexchangeControlNumber: "",
      sector: "",
      subsector: "",
      applicantComments: "",
      bankSupervisor: "",
    });
    if (BPNCodeRef.current) BPNCodeRef.current.value = "";
    if (TINRef.current) TINRef.current.value = "";
    if (amountRef.current) amountRef.current.value = "";
    if (applicantRef.current) applicantRef.current.value = "";
    if (applicantCommentsRef.current) applicantCommentsRef.current.value = "";
    if (applicantReferenceNumberRef.current)
      applicantReferenceNumberRef.current.value = "";
    if (applicationTypeRef.current) applicationTypeRef.current.value = "";
    if (bankSupervisorRef.current) bankSupervisorRef.current.value = "";
    if (companyNameRef.current) companyNameRef.current.value = "";
    if (currencyRef.current) currencyRef.current.value = "";
    if (govtAgencieRef.current) govtAgencieRef.current.value = "";
    if (purposeApplicationRef.current) purposeApplicationRef.current.value = "";
    if (relatedexchangeControlNumberRef.current)
      relatedexchangeControlNumberRef.current.value = "";
    if (sectorRef.current) sectorRef.current.value = "";
    if (subsectorRef.current) subsectorRef.current.value = "";
    if (typeExporterRef.current) typeExporterRef.current.value = "";
    if (usdEquivalentRef.current) usdEquivalentRef.current.value = "";
    if (rateRef.current) rateRef.current.value = "";
  };

  const handleChangecompany = (selectedOption) => {
    setgetCompanyName(selectedOption);
  };

  const handleInputChangecompany = (input) => {
    setInputValue(input);
    if (input.length >= 3) {
      const filteredOptions = companies
        ?.filter((company) =>
          company?.companyName?.toLowerCase().includes(input.toLowerCase())
        )
        ?.map((company) => ({
          value: company?.id,
          label: company?.companyName,
        }));
      setOptions(filteredOptions?.length > 0 ? filteredOptions : []);
    } else {
      // Reset options when input length is less than 3 characters
      setOptions([]);
    }
  };

  const filtertin_bpn = companies?.find((company) => {
    if (company.id === getCompanyName?.value) {
      return {
        getbpn: company.bpnNumber,
        gettin: company.tinNumber,
      };
    }
  });

  const handleClear = () => {
    setValue(null);
    setInputValue("");
    setOptions([]);
  };

  useEffect(() => {
    if (toastDisplayed) {
      setTimeout(() => {
        setToastDisplayed(false);
      }, 1500);
    }
  }, [toastDisplayed]);

  const SelectBankRecordOfficer = (e) => {
    const { name, value } = e.target;
    setGetBankID(value);
  };

  const GetHandelDetailRenew = async (rbzrefnumber, id) => {
    setLoading(true);
    await axios
      .post(APIURL + "ImportApplication/GetImportRequestInfoByApplicationID", {
        RBZReferenceNumber: `${rbzrefnumber}`,
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setLoading(false);
          setApplicationDetail(res.data.responseData);
        } else {
          setLoading(false);
          setApplicationmessage(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(APIURL + "ImportApplication/GetNewCommentsImport", {
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setallcomment(res.data.responseData);
        } else {
          setallcomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(APIURL + "ImportApplication/GetImportApplicationHistory", {
        ID: id,
        RoleID: roleID,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setTatHistory(res.data.responseData);
        } else {
          setTatHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(APIURL + "ImportApplication/GetActionsByApplicationID", {
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setActiondata(res.data.responseData);
        } else {
          setActiondata([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // --------------------------vishwas start----------------------------
    await axios
      .post(APIURL + "ImportApplication/GetImportCommentsInfoByRoleID", {
        ApplicationID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setNoDataComment(res.data.responseData);
        } else {
          setNoDataComment([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //---------------------------vishwas end------------------------------
  };

  const action = (rowData) => {
    return bankName.replace(/"/g, "") == rowData?.bankName ? (
      <>
        <i
          className="pi pi-eye p-0"
          style={{ padding: "12px", cursor: "pointer" }}
          onClick={() => {
            handleViewData(rowData.id);
            GetHandelDetailRenew(rowData?.rbzReferenceNumber, rowData.id);
            // GetApplicationCount(rowData.id);
          }}
          onMouseEnter={(e) => {
            e.target.style.color = "var(--primary-color)";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "";
          }}
        ></i>
      </>
    ) : (
      <i
        className="pi pi-eye p-0"
        style={{ padding: "12px", cursor: "pointer" }}
        onClick={() => {
          handleViewData(rowData.id);
          GetHandelDetailRenew(rowData?.rbzReferenceNumber, rowData.id);
          // GetApplicationCount(rowData.id);
        }}
        onMouseEnter={(e) => {
          e.target.style.color = "var(--primary-color)";
        }}
        onMouseLeave={(e) => {
          e.target.style.color = "";
        }}
      ></i>
    );
  };

  const amountData = (rowData) => {
    return (
      <span>
        {bankName.replace(/"/g, "") == rowData?.bankName
          ? rowData.currencyCode + " " + rowData.amount?.toLocaleString()
          : "--"}
      </span>
    );
  };

  const createdDate = (rowData) => {
    return <span>{moment(rowData?.createdDate).format("DD MMM YYYY")}</span>;
  };

  const applicantNAME = (rowData) => {
    return <span>{rowData?.name ? rowData?.name : "N/A"}</span>;
  };

  const renderFooter = () => {
    return (
      <div className="flex justify-content-end">
        <button
          className="validateCrossIcon"
          onClick={() => setValidateShow(false)}
        >
          <i className="bi bi-x-circle"></i>
        </button>
      </div>
    );
  };
  const footer = renderFooter();

  const validatePECANForm = () => {
    let valid = true;
    const newErrors = {};
    if (ValidateChange.PECANNumber.trim().length < 4) {
      newErrors.PECANNumber =
        "Prior exchange control authority number allow minimum 4 numbers";
      valid = false;
    } else if (ValidateChange.PECANNumber.trim().length > 6) {
      newErrors.PECANNumber =
        "Prior exchange control authority number allow maximum 6 numbers";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const changeHandelFormValidate = (e) => {
    const { name, value } = e.target;
    const specialChars = /[!@#$%^&*(),.?":{}|<>`~]/;
    let newErrors = {};
    let valid = true;
    if (name == "PECANNumber" && specialChars.test(value)) {
      newErrors.PECANNumber = "Special characters not allowed";
      valid = false;
    } else if (name == "PECANNumber" && value == " ") {
      newErrors.PECANNumber = "First character cannot be a blank space";
      valid = false;
    } else {
      setValidateChange({ ...ValidateChange, [name]: value });
    }
    setErrors(newErrors);
  };

  const handleValidateRBZ = () => {
    if (validatePECANForm()) {
      setLoader(true);
      axios
        .post(APIURL + "ImportApplication/ValidateRBZReferenceNumberImport", {
          RBZReferenceNumber: ValidateChange.PECANNumber.trim(),
        })
        .then((res) => {
          setErrors({});
          setValidateShow(true);
          setLoader(false);
          if (res.data.responseCode == "200") {
            setValidateRBZ(res.data.responseData);
          } else {
            setValidateRBZ([]);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <form>
        <div className="inner_form_new ">
          <label className="controlform">Name of Bank</label>
          {roleID == 4 ? (
            <div className="form-bx">
              <label>
                <select
                  className={
                    errors?.getBankID && getBankID == "" ? "error" : ""
                  }
                  name="BankID"
                  onChange={(e) => {
                    SelectBankRecordOfficer(e);
                  }}
                >
                  <option value="">Select Bank/ADLA Name</option>
                  {masterBank?.map((item, index) => {
                    return (
                      <>
                        <option value={item?.id} key={index}>
                          {item?.bankName}
                        </option>
                      </>
                    );
                  })}
                </select>
                <span className="sspan"></span>
                {/* {errors?.getBankID && getBankID == "" ? (
                    <small className="errormsg">{errors.getBankID}</small>
                  ) : (
                    ""
                  )} */}
              </label>
            </div>
          ) : (
            <div className="form-bx">
              <label>
                <input
                  type="text"
                  value={bankName.replace(/"/g, "")}
                  disabled
                />
                <span className="sspan"></span>
              </label>
            </div>
          )}
        </div>

        <div className="inner_form_new ">
          <label className="controlform">
            Application Date <i className="requierd_icons">*</i>
          </label>
          <div className="form-bx">
            <DatePicker
              closeOnScroll={(e) => e.target === document}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              placeholderText={
                startDate == null ? "Application Date" : startDate
              }
              maxDate={new Date()}
              minDate="01/01/2018"
              onKeyDown={(e) => {
                const key = e.key;
                const allowedKeys = /[0-9\/]/;
                if (
                  !allowedKeys.test(key) &&
                  key !== "Backspace" &&
                  key !== "Delete"
                ) {
                  e.preventDefault();
                }
              }}
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd/MMM/yyyy"
            />
            <span className="sspan"></span>
            {errors.date || startDate == null ? (
              <small className="errormsg" style={{ bottom: "-10px" }}>
                {/* {errors.date} */}
                Application Date is required
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end form-bx  */}

        <div className="inner_form_new ">
          <label className="controlform">
            Prior Exchange Control Authority Number(PECAN)
          </label>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <div className="form-bx">
                  <label>
                    <input
                      ref={PECANNumberRef}
                      type="number"
                      name="PECANNumber"
                      onChange={(e) => {
                        // changeHandelForm(e);
                        changeHandelFormValidate(e);
                      }}
                      value={ValidateChange.PECANNumber.trim()}
                      placeholder="PECAN"
                      className={
                        errors.PECANNumber
                          ? "text-uppercase error"
                          : "text-uppercase"
                      }
                    />
                    <span className="sspan"></span>
                    {errors.PECANNumber ? (
                      <small className="errormsg">{errors.PECANNumber}</small>
                    ) : (
                      ""
                    )}
                  </label>
                  {loader == true ? (
                    <TailSpin
                      visible={true}
                      height="20"
                      width="20"
                      color="#5e62a1"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : ValidateShow == true ? (
                    <div className="card validatepecanfield">
                      {ValidateRBZ.length > 0 ? (
                        <DataTable
                          value={ValidateRBZ}
                          scrollable
                          scrollHeight="500px"
                          paginator={ValidateRBZ?.length > 10 ? true : false}
                          rowHover
                          paginatorRight
                          rows={10}
                          dataKey="id"
                          rowsPerPageOptions={[10, 50, 100]}
                          emptyMessage="No Data found."
                          footer={footer}
                        >
                          <Column
                            field="rbzReferenceNumber"
                            header="RBZ Reference Number"
                            style={{ minWidth: "200px" }}
                          ></Column>
                          <Column
                            field="name"
                            header="Applicant Name"
                            style={{ minWidth: "180px" }}
                            body={applicantNAME}
                          ></Column>
                          <Column
                            field="bankName"
                            header="Bank Name"
                            style={{ minWidth: "150px" }}
                          ></Column>
                          <Column
                            field="applicationType"
                            header="Nature of Application"
                            style={{ minWidth: "250px" }}
                          ></Column>
                          <Column
                            field="amount"
                            header="Amount"
                            style={{ minWidth: "150px" }}
                            body={amountData}
                          ></Column>
                          <Column
                            field="statusName"
                            header="Status"
                            style={{ minWidth: "200px" }}
                          ></Column>
                          <Column
                            field="createdDate"
                            header="Submitted Date"
                            style={{ minWidth: "150px" }}
                            body={createdDate}
                          ></Column>
                          <Column
                            field=""
                            header="Action"
                            style={{ minWidth: "100px" }}
                            frozen
                            alignFrozen="right"
                            body={action}
                          ></Column>
                        </DataTable>
                      ) : (
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <p className="mb-0">No Data</p>
                          <button
                            className="validateCrossIcon"
                            onClick={() => setValidateShow(false)}
                          >
                            <i className="bi bi-x-circle"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
                <button
                  type="button"
                  className="primrybtn v-button"
                  onClick={() => handleValidateRBZ()}
                >
                  Validate
                </button>
              </div>
            </div>
            <div className="col-md-3 text-right"></div>
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">Type of Importer</label>
          <div className="form-bx-radio">
            {applicantTypes.map((item, index) => {
              return (
                <>
                  <label
                    key={index}
                    className={
                      bankID != "" && item.id === 3
                        ? "cur-dis"
                        : applicantTypeLocal == "3"
                        ? "cur-dis"
                        : ""
                    }
                  >
                    <input
                      type="radio"
                      ref={typeExporterRef}
                      onChange={(e) => {
                        changeHandelForm(e);
                        handleUsertype(e);
                      }}
                      name="importType"
                      value={item.id}
                      checked={registerusertype == item.id}
                      className={
                        bankID != "" && item.id === 3
                          ? "cur-dis"
                          : applicantTypeLocal == "3"
                          ? "cur-dis"
                          : ""
                      }
                      disabled={
                        (roleID != "" && item.id === 3) || roleID == "10"
                          ? true
                          : bankidcheck == "3"
                          ? true
                          : false
                      }
                    />
                    <span>{item.name}</span>
                  </label>
                </>
              );
            })}
          </div>
        </div>

        {registerusertype === "1" && bankID != "" ? (
          <>
            <div className="inner_form_new ">
              <label className="controlform">
                Company Name <i className="requierd_icons">*</i>
              </label>
              <div className="form-bx">
                <Select
                  ref={companyNameRef}
                  placeholder="Select Company Name"
                  value={getCompanyName}
                  onChange={handleChangecompany}
                  onInputChange={handleInputChangecompany}
                  options={options}
                  isSearchable
                  noOptionsMessage={({ inputValue }) =>
                    inputValue?.length > 3
                      ? "No Company found"
                      : "Type to search"
                  }
                  onMenuClose={handleClear}
                  className="selectinput"
                />

                {errors.companyName &&
                (getCompanyName === "Company Name" ||
                  getCompanyName == null) ? (
                  <small className="errormsg2">{errors.companyName}</small>
                ) : (
                  ""
                )}

                <small className="informgs">
                  Please provide at least 3 characters for auto search of
                  Company Name.
                </small>
              </div>
            </div>
            <div className="inner_form_new ">
              <label className="controlform">TIN Number</label>
              <div className="form-bx">
                <label>
                  <input
                    type="text"
                    name="TINNumber"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    placeholder="TIN Number"
                    disabled
                    value={
                      filtertin_bpn && filtertin_bpn.tinNumber !== null
                        ? filtertin_bpn?.tinNumber
                        : "TIN Number"
                    }
                    className={
                      errors.TINNumber
                        ? "error text-uppercase"
                        : "text-uppercase"
                    }
                  />
                  <span className="sspan"></span>
                  {errors.TINNumber ? (
                    <small className="errormsg">{errors.TINNumber}</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
            <div className="inner_form_new ">
              <label className="controlform">BPN Code</label>
              <div className="form-bx">
                <label>
                  <input
                    ref={BPNCodeRef}
                    type="text"
                    name="BPNCode"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    disabled
                    value={
                      filtertin_bpn && filtertin_bpn.bpnNumber !== null
                        ? filtertin_bpn?.bpnNumber
                        : "BPN Code"
                    }
                    placeholder="BPN Code"
                    className={
                      errors.BPNCode ? "error text-uppercase" : "text-uppercase"
                    }
                  />
                  <span className="sspan"></span>
                  {errors.BPNCode ? (
                    <small className="errormsg">{errors.BPNCode}</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {registerusertype === "2" && bankID != "" ? (
          <>
            <div className="inner_form_new ">
              <label className="controlform">
                Applicant <i className="requierd_icons">*</i>
              </label>
              <div className="form-bx">
                <label>
                  <input
                    type="text"
                    ref={applicantRef}
                    name="applicant"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    placeholder="Applicant"
                    value={ImportForm.applicant}
                    className={errors.applicant ? "error" : ""}
                  />
                  <span className="sspan"></span>
                  {errors.applicant || ImportForm.applicant === "" ? (
                    <small className="errormsg">{errors.applicant}</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="inner_form_new ">
          <label className="controlform">
            Nature of Application <i className="requierd_icons">*</i>
          </label>
          <div className="form-bx">
            <label>
              <select
                ref={applicationTypeRef}
                name="applicationType"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                className={
                  errors.applicationType && ImportForm.applicationType === ""
                    ? "error"
                    : ""
                }
              >
                <option value="">Select Nature of Application</option>
                {applicationType?.map((item, ind) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <span className="sspan"></span>
              {errors.applicationType && ImportForm.applicationType === "" ? (
                <small className="errormsg">{errors.applicationType}</small>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">Beneficiary Name</label>
          <div className="form-bx">
            <label>
              <input
                type="text"
                ref={BeneficiaryNameRef}
                name="BeneficiaryName"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                placeholder="Beneficiary Name"
                value={ImportForm.BeneficiaryName}
              />
              <span className="sspan"></span>
              {errors.BeneficiaryName || ImportForm.BeneficiaryName === "" ? (
                <small className="errormsg">{errors.BeneficiaryName}</small>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">Beneficiary Country</label>
          <div className="form-bx">
            <label>
              <select
                // ref={applicationTypeRef}
                name="baneficiaryCountry"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
              >
                <option value="">Select Beneficiary Country</option>
                {countries?.map((item, ind) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.countryName}
                    </option>
                  );
                })}
              </select>
              <span className="sspan"></span>
            </label>
          </div>
        </div>

        {bankID == "" ? (
          <div className="inner_form_new ">
            <label className="controlform">Government Agencies</label>
            <div className="form-bx">
              <label>
                <select
                  ref={govtAgencieRef}
                  name="govtAgencie"
                  onChange={(e) => {
                    changeHandelForm(e);
                  }}
                  className={
                    errors.govtAgencie && ImportForm.govtAgencie === ""
                      ? "error"
                      : ""
                  }
                >
                  <option value="">Select Government Agencies Name</option>
                  {GovernmentAgencies?.map((item, index) => {
                    return (
                      <option value={item.id} key={index}>
                        {item.agencyName}
                      </option>
                    );
                  })}
                </select>
                <span className="sspan"></span>
                {errors.govtAgencie && ImportForm.govtAgencie === "" ? (
                  <small className="errormsg">{errors.govtAgencie}</small>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-md-6">
            <div className="inner_form_new">
              <label className="controlform">
                Currency <i className="requierd_icons">*</i>
              </label>
              <div className="form-bx">
                <label>
                  <select
                    ref={currencyRef}
                    name="currency"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    className={
                      errors.currency && ImportForm.currency === ""
                        ? "error"
                        : ""
                    }
                  >
                    <option value="">Select Currency</option>
                    {currency?.map((cur, ind) => {
                      return (
                        <option key={cur.id} value={cur.id}>
                          {cur.currencyCode}
                        </option>
                      );
                    })}
                  </select>
                  <span className="sspan"></span>
                  {errors.currency && ImportForm.currency === "" ? (
                    <small className="errormsg">{errors.currency}</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
            {/* end form-bx  */}
          </div>

          <div className="col-md-3">
            <div className="inner_form_new-sm">
              <label className="controlform-sm">
                Amount <i className="requierd_icons">*</i>
              </label>
              <div className="form-bx-sm">
                <label>
                  <input
                    ref={amountRef}
                    type="number"
                    min={0}
                    name="amount"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    placeholder="Amount"
                    value={ImportForm.amount}
                    className={
                      errors.amount && ImportForm.amount === "" ? "error" : ""
                    }
                    onKeyDown={(event) => {
                      const blockedKeys = ["e", "E", "-", "+"];
                      if (blockedKeys.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span className="sspan"></span>
                  {errors.amount || ImportForm.amount === "" ? (
                    <small className="errormsg">{errors.amount}</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
            {/* end form-bx  */}
          </div>

          <div className="col-md-3">
            <div className="inner_form_new-sm">
              <label className="controlform-sm">Rate</label>
              <div className="form-bx-sm">
                <label>
                  <input
                    ref={rateRef}
                    type="text"
                    name="rate"
                    value={ImportForm.currency ? curRate : "Rate"}
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    placeholder="Rate"
                    disabled
                  />
                  <span className="sspan"></span>
                </label>
              </div>
            </div>
            {/* end form-bx  */}
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">USD Equivalent</label>
          <div className="form-bx">
            <label>
              <input
                ref={usdEquivalentRef}
                type="text"
                name="usdEquivalent"
                value={
                  ImportForm.currency && ImportForm.amount
                    ? convertedRate
                    : "USD Equivalent"
                }
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                placeholder="USD Equivalent"
                disabled
              />
              <span className="sspan"></span>
            </label>
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">
            Sector <i className="requierd_icons">*</i>
          </label>
          <div className="form-bx">
            <label>
              <select
                ref={sectorRef}
                name="sector"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                className={
                  errors.sector && ImportForm.sector === "" ? "error" : ""
                }
              >
                <option value="">Select Sector</option>
                {sectorData?.map((item, ind) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.sectorName}
                    </option>
                  );
                })}
              </select>
              <span className="sspan"></span>
              {errors.sector && ImportForm.sector === "" ? (
                <small className="errormsg">{errors.sector}</small>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="inner_form_new">
          <label className="controlform">
            Subsector{" "}
            {ImportForm.sector != "2" ? (
              <i className="requierd_icons">*</i>
            ) : (
              ""
            )}
          </label>
          <div className="form-bx">
            <label>
              <select
                ref={subsectorRef}
                name="subsector"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                disabled={ImportForm.sector === "" ? true : false}
                className={
                  errors.subsector && ImportForm.subsector === "" ? "error" : ""
                }
              >
                <option>Subsector</option>
                {subsectorData?.map((item, index) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.subSectorName}
                    </option>
                  );
                })}
              </select>
              <span className="sspan"></span>
              {errors.subsector && ImportForm.subsector === "" ? (
                <small className="errormsg">{errors.subsector}</small>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="inner_form_new ">
          <label className="controlform">
            Applicant Comments <i className="requierd_icons">*</i>
          </label>
          <div className="form-bx">
            <label>
              <textarea
                ref={applicantCommentsRef}
                name="applicantComments"
                onChange={(e) => {
                  changeHandelForm(e);
                }}
                placeholder="Applicant Comments"
                className={errors.applicantComments ? "error" : ""}
              />
              <span className="sspan"></span>
              {errors.applicantComments ||
              ImportForm.applicantComments === "" ? (
                <small className="errormsg">{errors.applicantComments}</small>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className={roleID == 4 ? "d-none" : "inner_form_new"}>
          <label className="controlform">
            Submit To {roleID == 2 ? "Supervisor" : "Next Level"}
          </label>
          <input
            type="checkbox"
            // className="mt-4"
            disabled={roleID == 4 ? true : false}
            onChange={(e) => {
              HandelSupervisorcheck(e);
            }}
          />
        </div>

        {checkSupervisor == true && roleID == 2 ? (
          <div className="inner_form_new ">
            <label className="controlform">
              Select Bank Supervisor <i className="requierd_icons">*</i>
            </label>
            <div className="form-bx">
              <label>
                <select
                  ref={bankSupervisorRef}
                  name="bankSupervisor"
                  onChange={(e) => {
                    changeHandelForm(e);
                  }}
                  className={
                    errors.bankSupervisor && ImportForm.bankSupervisor === ""
                      ? "error"
                      : ""
                  }
                >
                  <option value="" selected>
                    Select Bank Supervisor
                  </option>
                  {ImpSupervisors?.map((item, index) => {
                    return (
                      <option key={index} value={item.userID}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <span className="sspan"></span>
                {errors.bankSupervisor && ImportForm.bankSupervisor === "" ? (
                  <small className="errormsg">{errors.bankSupervisor}</small>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}

        {checkSupervisor == true && roleID == 4 ? (
          <div className="inner_form_new ">
            <label className="controlform">
              RBZ Record Officer Submit to <i className="requierd_icons">*</i>
            </label>
            <div className="form-bx">
              <label>
                <select
                  name="SupervisorRoleId"
                  onChange={(e) => {
                    supervisorHangechangeRole(e);
                  }}
                >
                  <option value="">Select Role</option>
                  {userRole?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.designation}
                      </option>
                    );
                  })}
                </select>
                <span className="sspan"></span>
                {errors.selectuserRole && selectuserRole === "" ? (
                  <small className="errormsg">Role is required</small>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        ) : (
          ""
        )}

        {checkSupervisor == true && roleID == 4 && selectuserRole ? (
          <div className="w-100">
            <div className="inner_form_new">
              <label className="controlform">
                User <i className="requierd_icons">*</i>
              </label>

              <div className="form-bx">
                <label>
                  <select
                    ref={bankSupervisorRef}
                    name="bankSupervisor"
                    onChange={(e) => {
                      changeHandelForm(e);
                    }}
                    className={
                      errors.bankSupervisor && ImportForm.bankSupervisor === ""
                        ? "error"
                        : ""
                    }
                  >
                    <option value="" selected>
                      Select User
                    </option>
                    {getalluser?.map((item, index) => {
                      return (
                        <option key={index} value={item.userID}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="sspan"></span>
                  {errors.bankSupervisor && ImportForm.bankSupervisor === "" ? (
                    <small className="errormsg">User is required</small>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            roleID == 2
              ? "inner_form_new align-items-start mt-3 mb-4"
              : "d-none"
          }
        >
          <label className="controlform mt-0">
            Recommendation <i className="requierd_icons">*</i>
          </label>
          <div className="form-bx editorFieldBox">
            <div className="mt-2 py-1">
              <MenuBar editor={editor} ref={supervisorDesc} />
              <EditorContent editor={editor} />
              <span className="sspan"></span>
              {(errors.Description && Description == " ") ||
              Description == null ||
              Description == "<p></p>" ||
              !Description ? (
                <small className="errormsg" style={{ bottom: "-18px" }}>
                  {errors.Description}
                </small>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <h5 className="section_top_subheading mt-3">Attachments</h5>

        {attachmentData?.map((items, index) => {
          return (
            <div className="attachemt_form-bx" key={items.id}>
              <label>
                <i className="bi bi-forward"></i>
                {items.name}
              </label>
              <div className="browse-btn">
                Browse{" "}
                <input
                  type="file"
                  ref={fileInputRefs[index]}
                  onChange={(e) => handleFileChange(e, items.name)}
                />
              </div>
              {/* <span className="filename">
                {files.find((f) => f.id === items?.id)?.file?.name ||
                  "No file chosen"}
              </span> */}
              <span className="filename">
                {files.find((f) => f.label === items?.name)?.file?.name ||
                  "No file chosen"}
              </span>

              {files?.length &&
              files?.find((f) => f.label === items.name)?.file?.name ? (
                <button
                  type="button"
                  className="remove-file"
                  onClick={() => {
                    removeUserImage(items?.name);
                    clearInputFile(index);
                  }}
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </div>
          );
        })}

        {otherfiles.map((file, index) => (
          <div key={"other" + (index + 1)} className="attachemt_form-bx">
            <label>
              <i className="bi bi-forward"></i> Other File {index + 1}
            </label>
            <div className="browse-btn">
              Browse{" "}
              <input
                type="file"
                ref={fileInputRefsother[index]}
                onChange={(e) => {
                  handleFileChange(e, "other" + (index + 1));
                  handleOthrefile(e, "other" + (index + 1));
                }}
              />
            </div>
            <span className="filename">
              {files.find((f) => f.label === "other" + (index + 1))?.file
                ?.name || "No file chosen"}
            </span>

            {files?.length &&
            files?.find((f) => f.label === "other" + (index + 1))?.file
              ?.name ? (
              <button
                type="button"
                className="remove-file"
                onClick={() => {
                  removeUserImage("other" + (index + 1));
                  clearInputFileother(index);
                }}
              >
                Remove
              </button>
            ) : (
              ""
            )}
          </div>
        ))}

        {attachmentData?.length ? (
          <button
            type="button"
            className="addmore-btn"
            onClick={(e) => handleAddMore(e)}
          >
            {" "}
            Add More File{" "}
          </button>
        ) : (
          ""
        )}

        <div className="form-footer mt-5 mb-3">
          <button
            type="reset"
            onClick={(e) => {
              ResetHandleData(e);
            }}
            className="register"
          >
            Reset
          </button>
          <button
            type="button"
            onClick={(e) => {
              HandleSubmit(e);
            }}
            className="login"
            disabled={submitbuttonhide == true}
          >
            {submitbuttonhide == true ? (
              <>
                Submit<div className="smallloader"></div>
              </>
            ) : (
              "Submit"
            )}
          </button>
        </div>
        {updatepopup == true ? (
          <UpdatePopupMessage
            heading={heading}
            para={para}
            closePopupHandle={closePopupHandle}
          ></UpdatePopupMessage>
        ) : (
          ""
        )}
      </form>
      <Modal
        show={showUpdateModal}
        onHide={handleFormClose}
        backdrop="static"
        className="max-width-600"
      >
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>
                    View Import Request --{" "}
                    <big>{applicationDetail?.rbzReferenceNumber}</big>
                  </Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <ImportDashboardViewDetails
                  applicationDetail={applicationDetail}
                  handleFormClose={handleFormClose}
                  allcomment={allcommentRenew}
                  noDataComment={noDataCommentRenew}
                  tatHistory={tatHistoryRenew}
                  Actiondata={Actiondata}
                />
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportNewRequestForm;
