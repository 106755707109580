import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthUser from "../../login/AuthUser";
import Modal from "react-bootstrap/Modal";
import { Storage } from "../../login/Storagesetting";

const Header = () => {
  // const navigation = useNavigate();

  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");
  const [logoutLoaderShow, setlogoutLoaderShow] = useState(false);

  const handlelogoutLoaderClose = () => setlogoutLoaderShow(false);
  const handlelogoutLoaderShow = () => setlogoutLoaderShow(true);

  const { token, logout } = AuthUser();

  const [isToggled, setIsToggled] = useState(false);

  const userName = Storage.getItem("userName");

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
    if (!isToggled) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  };

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

    return () => {
      document.removeEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behavior for specific key combinations

      // if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      //   e.preventDefault();
      //   console.log('Ctrl + Shift + I is disabled');
      // }

      if (e.ctrlKey && e.keyCode === 85) {
        e.preventDefault();
        console.log("Ctrl + U is disabled");
      }

      // if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117)) {
      //   e.preventDefault();
      //   console.log('Ctrl + C, Ctrl + V, Ctrl + U, and Ctrl + F6 are disabled');
      // }
    };

    const handleKeyPress = (e) => {
      // Prevent default behavior for Ctrl + U
      if (e.ctrlKey && e.key === "u") {
        e.preventDefault();
        console.log("Ctrl + U is disabled");
      }
    };

    // Attach event listeners
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keypress", handleKeyPress);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const logoutUser = () => {
    if (token != undefined || token == null) {
      handlelogoutLoaderShow();
      logout();
    }
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      {/* <div className="d-flex align-items-center justify-content-between">
      <Link to="/dashboard" className="logo d-flex align-items-center">
        <img src={logo} alt="" />
        <span>Document Management System</span>
      </Link>

      <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggle}></i>
    </div> */}

      <div className="pagetitle">
        <h1>
          <span>{menuname ? menuname : "User Management"}</span>{" "}
          {submenuname ? submenuname : "Pending User"}{" "}
        </h1>
        <div
          className=" toggle-headerextra-lg"
          onClick={() => {
            handleToggle();
          }}
        >
          <i class="bi bi-list"></i>
        </div>
      </div>

      <div className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item  ">
            <Link className="nav-link nav-icon">
              {" "}
              <span>Welcome {userName?.replace(/"/g, "")}</span>
            </Link>
          </li>

          <li className="nav-item dropdown pe-2 nav-item-wlcm">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <span className="d-none d-md-block dropdown-toggle ps-2">
                Profile
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li>
                <Link className="dropdown-item d-flex align-items-center">
                  {userName?.replace(/"/g, "")}
                </Link>
                {/* <select
                  className="dropdown-item d-flex align-items-center"
                  to=""
                >
                  <option>{userName?.replace(/"/g, "")}</option>
                  <option>Bank User</option>
                  <option>Govt User</option>
                </select> */}
              </li>

              <li>
                <Link className="dropdown-item d-flex align-items-center" to="">
                  Change Password
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item d-flex align-items-center"
                  to="/"
                >
                  User Settings
                </Link>
              </li>
            </ul>
          </li>

          {/* <li className="nav-item  dropdown pe-3">
          <span className="nav-link nav-profile d-flex align-items-center pe-0">
            <span className="d-none d-md-block  ps-2">
              {userName?.replace(/"/g, "")}{" "}
            </span>
            <div className="form-check form-switch px-4">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Switch User</label>
                  </div>
          </span>
        </li> */}
          {/* <li className="nav-item  ">
          <Link
            className="nav-link nav-profile d-flex align-items-center pe-0"
            to=""
          >
            Dashboard
          </Link>
        </li>
        */}
          <li className="nav-item  ">
            <button
              className="nav-link nav-profile d-flex align-items-center pe-0"
              type="button"
              onClick={logoutUser}
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
      <Modal
        show={logoutLoaderShow}
        onHide={handlelogoutLoaderClose}
        backdrop="static"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <label className="outerloader2">
            <span className="loader"></span>
            <span className="loaderwait">Please Wait...</span>
          </label>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;
