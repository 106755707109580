import React from "react";
import { Helmet } from "react-helmet";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import SectorMasterTable from "../tables/SectorMasterTable";
import { Storage } from "../../login/Storagesetting";
import DashboardLayout from "../../components/DashboardLayout";

const SectorMaster = () => {
  const roleId = Storage.getItem("roleIDs");

  const Layout = roleId === "1" ? AdminDashboardLayout : DashboardLayout;

  return (
    <>
      <Helmet>
        <title>Sector</title>
      </Helmet>
      <Layout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item active">Sector</li>
          </ol>
        </nav>

        <section className="section dashboard adminDashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">Sector</h4>
                {/* SectorMaster tableData */}
                <SectorMasterTable />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SectorMaster;
