import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { APIURL } from "../../constant";
import axios from "axios";
import { toast } from "react-toastify";
import ErrorsLogApi from "../components/ErrorsLogApi";
import { Storage } from "../../login/Storagesetting";
import Modal from "react-bootstrap/Modal";

import Menubar from "../../navbar/Menubar";
const ImpersonateUserTable = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [userDetails, setUserDetails] = useState({
    user: "",
    pass: "",
  });
  const [loginModalShow, setloginModalShow] = useState(false);
  const handleLoginModalClose = () => setloginModalShow(false);
  const handleLoginModalShow = () => setloginModalShow(true);
  const [loader, setLoader] = useState(false);
  const [insLoader, setInsLoader] = useState(false);
  const menuname = Storage.getItem("menuname");
  const { ErrorlogData } = ErrorsLogApi();
  const handleUserLoginData = (loginUser, loginPass) => {
    setloginModalShow(true);
    setUserDetails({
      user: loginUser,
      pass: loginPass,
    });
  };
  const { getMenudata } = Menubar();

  const handleClick = (title) => {
    alert(`Title: ${title}`);
  };

  // approve user list api start
  const table_Data = async () => {
    setLoader(true);
    const Status = {
      Status: "10",
    };
    try {
      const response = await fetch(APIURL + "User/GetImpersonateUsers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Status),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.responseCode == "200") {
        setTableData(data.responseData);
        setLoader(false);
      } else if (data.responseMessage == "No Data") {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // approve list api end
  // -------- login api start

  const adminUseridLocal = Storage.getItem("userID");
  const adminToken = Storage.getItem("loginToken");

  const handleLoginApi = async () => {
    setInsLoader(true);
    const userDetail = {
      UserID: userDetails?.user,
      Token: adminToken?.replace(/"/g, ""),
      AdminUserID: adminUseridLocal?.replace(/"/g, ""),
    };
    const jsonString = JSON.stringify(userDetail);

    const base64String = Buffer.from(jsonString).toString("base64");

    await axios
      .post(APIURL + "User/ImpersonateUser", {
        UserToken: new Buffer(base64String).toString("base64"),
      })
      .then((res) => {
        // console.log("res------",res);

        if (res.data.responseCode === "200") {
          Storage.setItem(
            "userID",
            JSON.stringify(res.data.responseData.userID)
          );
          Storage.setItem(
            "userName",
            JSON.stringify(res.data.responseData.userName)
          );
          Storage.setItem(
            "applicantType",
            JSON.stringify(res.data.responseData.applicantType)
          );
          Storage.setItem("name", JSON.stringify(res.data.responseData.name));
          Storage.setItem(
            "roleIDs",
            JSON.stringify(res.data.responseData.roleID)
          );
          Storage.setItem(
            "roleName",
            JSON.stringify(res.data.responseData.roleName)
          );
          Storage.setItem(
            "bankID",
            JSON.stringify(res.data.responseData.bankID)
          );
          Storage.setItem(
            "bankName",
            JSON.stringify(res.data.responseData.bankName)
          );
          getMenudata(
            res.data.responseData.roleID,
            res.data.responseData.loginToken,
            res.data.responseData.userID
          ).then((res) => {
            setInsLoader(false);
            setloginModalShow(false);
          });
          sessionStorage.setItem("menuname", "Exports");
          sessionStorage.setItem("submenuname", "Dashboard");
          toast.success(res.data.responseMessage);
        } else {
          setInsLoader(false);
          handleLoginModalClose();
          toast.warn(res.data.responseMessage);
        }
      });
  };

  // --------- Login api end
  useEffect(() => {
    table_Data();
  }, []);

  const columns = [
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: true,
      searchable: true,
      width: "18%",
    },
    {
      name: "Full Name",
      // selector: (row) => row.bankName,
      selector: function (row) {
        return row.name ? row.name : "_";
      },
      sortable: true,
      searchable: true,
      width: "18%",
    },
    {
      name: "Email Address",
      selector: function (row) {
        return row.emailID ? row.emailID : "_";
      },
      sortable: true,
      searchable: true,
      width: "20%",
    },
    {
      name: "Contact Number",
      selector: function (row) {
        return row.phoneNumber ? row.phoneNumber : "_";
      },
      sortable: true,
      searchable: true,
      width: "17%",
    },
    {
      name: "Registration Type",
      selector: function (row) {
        return row.applyingFor ? row.applyingFor : "_";
      },
      sortable: true,
      searchable: true,
      width: "17%",
    },
    {
      name: "Action",
      width: "10%",
      cell: (row) => (
        <>
          <button
            className="impersonateLoginBtn"
            onClick={() => handleUserLoginData(row.userID)}
          >
            login
          </button>
        </>
      ),
    },
  ];

  const filteredData = tableData?.filter((item) =>
    item.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      {loader == true ? (
        <label className="outerloader2">
          <span className="loader"></span>
          <span className="loaderwait">Please Wait...</span>
        </label>
      ) : (
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          persistTableHead={true}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          paginationRowsPerPageOptions={[10, 50, 100]}
          highlightOnHover
          dense
          striped
          fixedHeader
          subHeader
          subHeaderComponent={
            <div className="admintablesearch">
              <div className="tablesearch_bx">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="table-btn-bx"></div>
            </div>
          }
        />
      )}
      {/* login conformation modal start */}
      <Modal
        className="impersonateLogin"
        show={loginModalShow}
        onHide={handleLoginModalClose}
        backdrop="static"
        centered
      >
        {insLoader ? (
          <Modal.Body>
            <label className="outerloader2">
              <span className="loader"></span>
              <span className="loaderwait">Please Wait...</span>
            </label>
          </Modal.Body>
        ) : (
          <>
            <Modal.Header>
              {/* <Modal.Title>Modal heading</Modal.Title> */}
              <div>
                <i className="bi bi-exclamation-circle"></i>
              </div>
            </Modal.Header>
            <Modal.Body>Do you want to impersonate the user?</Modal.Body>
            <Modal.Footer>
              <button className="btn impNoBtn" onClick={handleLoginModalClose}>
                No
              </button>
              <button
                className="btn impyesBtn"
                onClick={() => handleLoginApi()}
              >
                Yes
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      {/* login conformation modal end */}
    </>
  );
};

export default ImpersonateUserTable;
